//icons
import { Icon } from '@iconify/react';

const Footer = () => {
    return (
        <footer>
            <a href="#" className="goToTop" title='Go to top'><Icon className='upArrowIcon' icon="bi:arrow-up-square-fill" /></a>
            <small><p className='gradient-text1'>Copyright &copy; 2022 - </p><a href="https://github.com/jeevesh2002" className="githubLink gradient-text" target="_blank">https://github.com/jeevesh2002</a></small>
        </footer>
    );
}

export default Footer;