//components
import OtherProject from "./Technology";
import React from 'react';



const technologies = [
    {
        tech: ["Qiskit", "QuTech", "Cirq", "PyQuil", "tensorflow", "scikit-learn", "pandas", "numpy", "django", "flask", "react", "express", "node", "aws"],
        title: "Frameworks and Technologies"
    },
    {
        tech: ["OpenQasm", "Q#", "python", "javascript", "c", "bash", "java", "html", "css"],
        title: "Languages"
    },
    {
        tech: ["mongodb", "mysql", "oracle", "postman", "heroku", "render", "netlify", "firebase", "docker", "linux", "vim", "vscode", "jupyter", "git", "github", "gitlab"],
        title: "Databases and Tools"
    }
];

const MyTechnology = () => {
    return (
        <div className="container">
            <h2 className="containerHeader"><span className='red'></span><span className="gradient-text1">-&gt; Skills</span></h2>
            <div className='otherProjectGrid'>
                {technologies.map((tech) => (
                    <OtherProject title={tech.title} tech={tech.tech} key={tech.title} />
                ))}
            </div>
        </div>
    );
}

export default MyTechnology;