//hooks
import { useState } from "react";
//icons
import { Icon } from '@iconify/react';

const Email = () => {

    const [copySuccess, setCopySuccess] = useState('');

    const handleClick = () => {
        navigator.clipboard.writeText('jeeveshkrishna2010708@ssn.edu.in')
        setCopySuccess('copied!')
        setTimeout(() => {
            setCopySuccess('')
        }, 1000)
    }

    return (
        <div className='emailContainer'>

            <small className="copySuccess">{copySuccess}</small>
            <Icon icon="bx:copy" className="copyIcon" onClick={handleClick} />


            <a href="mailto:jeeveshkrishna2010708@ssn.edu.in" className="email" >
                jeeveshkrishna2010708@ssn.edu.in
            </a>

            <div className='line'></div>
        </div>
    );
}

export default Email;