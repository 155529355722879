import styled from 'styled-components';
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;
    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const Technology = ({ title, tech}) => {
    return (
        <StyledText>
        <div className="otherTechnology">
            <div className="otherTechnologyTop">
                <div className='otherTechnologyText'>
                    <div className="otherTechnologyTitle">{title}</div>
                    <ul className="skills-list">
                        {tech && tech.map((t) => (
                            <li key={t}>{t}</li>
                        ))}
                    </ul>
                   
                </div>
            </div>
           

        </div>
        </StyledText>
    );
}

export default Technology;