
//icons
import { Icon } from '@iconify/react';
//hooks
import { useState } from 'react';



const AboutMe = () => {

    const [readMore, setReadMore] = useState(false)



    return (
        <div className="container" id='aboutMe'>
            <h2 className="containerHeader"><span className='red'>01.</span><span className='gradient-text'>aboutMe</span></h2>
            <div className="innerContainer">
                <div className="aboutMe">
                    <p className="aboutMeIntro">"A passionate software developer with an exceptional eye for detail and a constant drive to improve personal and technical skills"</p>
                    <br /><br />
                    A Computer Science Undergrad at <a href='www.ssn.edu.in'>SSN College of Engineering</a>. I have gained in-depth knowledge of the field of quantum computing, and I am constantly upskilling myself in this domain. I have a strong background in machine learning and data science, with extensive experience in deep learning, as well as web scraping, system administration, and reverse engineering (with a focus on cybersecurity). 

                    <br /><br />
                    As a Full Stack Developer, I have designed and built solutions using various software languages and frameworks, including proficiency in Python and a strong understanding of libraries and tools. In addition to my experience with machine learning models and deploying them using Flask and TensorFlow frameworks, I have a solid foundation in several programming languages, such as Js, Py, C, and Java, and am fluent in Bash and other scripting languages.

                    <br /><br />
                    Currently, my research is focused on post-quantum cryptography, notably, cryptography based on the hardness of lattice problems. I am fascinated by the potential of quantum computing to solve complex problems and excited to continue my learning in this emerging field.



                </div>
                <br />
                <div className={`aboutMe aboutMe2 ${readMore ? 'grow' : ''}`}>
                    <br />
                    In addition to my quantum computing research, I have worked on several facets of Machine Learning and Artificial Intelligence, including Natural Language Processing, Time-Series Forecasting, and RNN. I have a passion for building successful products and have actively participated in small and large team projects as a team player. My background in other STEM subjects gives me a broad understanding of technology, allowing me to design and build products that make a difference in the field.
                    </div>

                <div className="btn btn-primary readMore" onClick={() => setReadMore(!readMore)}>
                    {readMore ? 'close' : 'readMore'}
                    {readMore ? <Icon icon="charm:chevron-up" className='chevronIcon' />
                        : <Icon icon="ci:chevron-big-down" className='chevronIcon' />}
                </div>

            </div>
        </div>
    );
}

export default AboutMe;