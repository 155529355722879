//icons
import { Icon } from '@iconify/react';

const Navbar = ({ setMenuOpen, menuOpen }) => {

    const handleHamburgerClick = () => {
        setMenuOpen(!menuOpen)
    }

    return (
        <ul className="navbar">

            <li className="mainLogo">
                <a href="/" className="firstContainer">
                    <span>{'<'}</span>
                    <span className='firstName'>Jeevesh Krishna
                    </span>
                    <span>{'/>'}</span>

                </a>
                <a href="/" className="lastContainer">
                    <span>{'<'}</span>
                    <span className='lastName'>Arigala</span>
                    <span>{'/>'}</span>

                </a>

            </li>
            <li className="navbarLinks">
                <ul>
                    <li className='lastUpdated'><span>Last Updated</span><br></br>26th May 2023</li>
                    <li><a href="#aboutMe" className='navbarLink'><span className='red'>01.</span>aboutMe</a></li>
                    <li><a href="#myProjects" className='navbarLink'><span className='red'>02.</span>myProjects</a></li>
                    <li><a href="#contactMe" className='navbarLink'><span className='red'>03.</span>contactMe</a></li>
                    <li><a href="https://api.jeeveshkrishna.com/resume" className='navbarLink'><span className='red'>04.</span>resuMe</a></li>
                </ul>
            </li>
            <li className="hamburgerIconContainer" onClick={handleHamburgerClick}>
                <Icon icon="codicon:three-bars" className='hamburgerIcon' />
            </li>
            
        </ul>
    );
}

export default Navbar;