//bootstrap
import Carousel from 'react-bootstrap/Carousel'
//css
import 'bootstrap/dist/css/bootstrap.min.css';
//hooks
import { useState } from 'react';
//icons
import { Icon } from '@iconify/react';
//animate on scroll
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
    once: true,
    mirror: false,
    anchorPlacement: 'center-bottom'
});

const Extras = ({title, position, date, details, link, images}) => {

    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
   

    return (
        <div className="extra" data-aos="fade-in" data-aos-duration="0">
            
            <div className="extraLeft">
                <h3 className="extraTitle">{title}</h3>
                <h3 className="extraPosition">{position}</h3>
                <p className='extraDate'>{date}</p>
                <hr cla ssName="extraHr" />
                <p className="extraDetails">{details}</p>
                <div className="extraLinks">
                    <a href={link} className="btn btn-primary" target={'_blank'}>More Details</a>
                </div>
            </div>
            <div className="extraLeft">
                <div className='extraImageContainer'>
                    <Carousel activeIndex={index} onSelect={handleSelect} interval={5000} touch={true}>
                        {images && images.map((image) => (
                            <Carousel.Item key={image}>
                                <img
                                    className="d-block w-100"
                                    src={`${ image }`}
                                    alt="slide"
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>

                <div className="extraImageShadow"></div>
            </div>
        </div>
    );
}

export default Extras;