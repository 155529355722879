import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
//icons
// import { Icon } from '@iconify/react';
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(auto, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 0px 0 0 0;
    overflow: hidden;
    list-style: none;
    li {
        color: var(--medium-red);
    
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--medium-red);
        font-size: var(--fz-sm);
        line-height: 15px;
      }
    }
  }
`;

const Courses = () => {
  const [courses, setCourses] = useState([
    "Quantum Algorithms and Cryptography (IITM)",
    "AWS Machine Learning Foundations (AWS)",
    "CS50 Introduction to Computer Science (Harvard)",
    "CS50 Introduction to Artificial Intelligence with Python (Harvard)",
    "Into to Tensorflow for Deep Learning (Google)",
    "MIT Introduction to Deep Learning (MIT6.S191)",
    "Convolutional Neural Networks in Tensorflow (DeepLearning.AI)",
    "Introduction to TensorFlow for Artificial Intelligence, Machine Learning, and Deep Learning (DeepLearning.AI)"
  ]);

  return (
    <div className="container" id="aboutMe">
      <div className='container-fluid'>
        <h2 className="containerHeader"><span className='red'></span><span className='gradient-text'>-&gt; Courses</span></h2>
        <StyledText>
          <p>
            <ul className="skills-list">
              {courses && courses.map((t) => (
                <li key={t}>{t}</li>
              ))}
            </ul>
          </p>
        </StyledText>
      </div>
    </div>
  );
}

export default Courses;