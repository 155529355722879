//components
import Project from "./Project";
import OtherProject from "./OtherProject";
//icons
import { Icon } from '@iconify/react';
//hooks
import { useState } from "react";


const projects = [
    {
        title: 'Kodex Draw',
        tech: ['js', 'html', 'css', 'sockets', 'paper.js', 'hammer.js'],
        extraInfo: "A Drawing canvas that uses web sockets for real-time bidirectional event-based communication that helps in collaboration. The app also smoothens whatever you draw giving you more control over the diagram which can be used on a Digital Board in Classrooms.",
        images: ['https://github.com/jeevesh2002/assets/raw/master/kodex-draw.gif','https://github.com/jeevesh2002/assets/raw/master/kodex-draw.jpg'],
        repo: 'https://github.com/jeevesh2002/Kodex-Draw',
        link: 'https://kodex-draw.netlify.app/',
        type: 'personal project'
    },
    {
        title: 'AI Tic-Tac-Toe',
        tech: ['python', 'pygame', 'minimax', 'alpha-beta pruning'],
        extraInfo: 'A Tic-Tac-Toe game that uses the minimax algorithm with alpha-beta pruning to make the AI unbeatable. The AI uses a heuristic function to evaluate the board and make the best move.',
        images: ['https://github.com/jeevesh2002/assets/blob/master/tic-tac-toe.gif?raw=true'],
        link: 'https://github.com/jeevesh2002/Tic-Tac-Toe',
        type: 'harvard ai project'
    },
    {
        title: 'mApantser-Blog',
        tech: ['flask','jinja', 'python', 'firebase', 'cloude firestore', 'bootstrap', 'heroku', 'netlify', 'html', 'css', 'js'],
        extraInfo: 'A Blogging website that uses Flask as the backend and Jinja as the templating engine. It uses Firebase for authentication and Cloud Firestore for storing the data. The app is hosted on Heroku and Netlify. The app facilitates creation and management of blog posts and tracks stats and analytics. Tags can also be added to each blog post to organize and find them later easily.',
        images: ['https://github.com/jeevesh2002/assets/blob/master/mapantser-home-wide.png?raw=true','https://github.com/jeevesh2002/assets/blob/master/mapantser-recent-wide.png?raw=true','https://github.com/jeevesh2002/assets/blob/master/mapantser-mobile.png?raw=true', 'https://github.com/jeevesh2002/assets/blob/master/mapantser-home-mobile.png?raw=true', 'https://github.com/jeevesh2002/assets/blob/master/mapantser-contact-med.png?raw=true'],
        link: 'https://mapantser.netlify.app/',
        type: 'project made for a friend'
    },
    {
        title: 'Paint Car',
        tech: ['vanilla js', 'html', 'css', 'canvas', 'netlify'],
        extraInfo: 'A simple paint car game made using vanilla js, html and css. It uses the canvas element to draw the car and the road. It also uses the requestAnimationFrame() method to animate the car and the road. It is hosted on Netlify.',
        images: ['https://github.com/jeevesh2002/assets/blob/master/paint-car.gif?raw=true', 'https://github.com/jeevesh2002/assets/blob/master/paint-car.jpg?raw=true'],
        link: 'https://jeevesh-car-game.netlify.app/',
        type: 'personal project'
    },
    {
        title: 'Recover',
        tech: ['c', 'jpeg', 'bmp', 'png'],
        extraInfo: 'A program that recovers JPEGs from a forensic image. It uses the C programming language and the properties of JPEGs to recover the images.',
        images: ['https://github.com/jeevesh2002/assets/blob/master/recover.gif?raw=true'],
        link: 'https://github.com/jeevesh2002/Recover',
        type: 'harvard cs50 project'
    },
    {
        title: 'Maze Solver',
        tech: ['python', 'pygame', 'breadth first search', 'manhattan distance', 'pillow'],
        extraInfo: 'A program that solves mazes using the breadth first search algorithm. It uses the pillow and pygame library to draw the maze and the solution',
        images: ['https://github.com/jeevesh2002/assets/blob/master/maze.gif?raw=true'],
        link: '',
        type: 'personal project'
    },


    

];

const otherProjects = [

    {
        title: 'CTF Cybersecurity',
        tech: ['reverse engineering', 'side-channel attacks', 'forensics', 'pwn', 'misc', 'python', 'c', 'bash', 'html', 'css', 'js'],
        extraInfo: 'A work in progress CTF website that hosts question i created from scratch. Topics include reverse engineering, side-channel attacks, forensics, assembly, pwn, python, scripting, steganography, cryptography, misc, web, and more.',
        link: 'https://jeevesh2002.github.io/level1-hello-friend.mov',
        type: 'personal project'
    },

    {
        title: 'Automated Attendance System',
        tech: ['python', 'opencv', 'keras', 'tensorflow', 'dlib', 'google-sheets api', 'pillow'],
        extraInfo: 'A program that uses face recognition to mark attendance. It uses the face-recognition module which is built on top of OpenCV and dlib library to detect faces and encode the facial features. It uses the Google Sheets API to mark attendance in a google sheet. It uses the Keras library to train the model.',
        link: 'https://github.com/jeevesh2002/AAMS',
        type: 'personal project'
    },

    {
        title: 'old portfolio',
        tech: ['vanillajs', 'css', 'html', 'gh-pages'],
        extraInfo: "My old portfolio website that i made using vanilla js, html and css. It is hosted on github pages.",
        link: 'https://jeevesh2002.github.io/jeevesh/',
        type: 'personal project'
    },

    {
        title: '2 Player Tic-Tac-Toe over LAN',
        tech: ['java', 'socket programming', 'swing'],
        extraInfo: 'Multiplayer Tic-Tac-Toe game that can be played over LAN. It uses the Java Swing library to create the GUI and the Java Socket Programming to create the server and the client.',
        link: 'https://github.com/jeevesh2002/Tic-Tac-Toe-over-LAN--java',
        type: '3rd semester project'
    },

    {
        title: 'eNcRyPtOr',
        tech: ['python'],
        extraInfo: 'A command line tools that encrypts and decrypts files/folders. It uses a very basic shift cipher to encrypt and decrypt the files. Please note that this is not a secure encryption method and should not be used to encrypt sensitive data. Please use it at your own risk, i am not responsible for any damage caused by this program.',
        link: 'https://github.com/jeevesh2002/encrypt',
        type: 'personal project'
    },

    {
        title: 'Wordle Solver CLI',
        tech: ['python', 'bash'],
        extraInfo: 'A CLI tool that solves the famous wordle game. It is written in python and uses the Global Regular Expression Print (GREP) command to find the words and solve the puzzle.',
        link: 'https://github.com/jeevesh2002/wordle',
        type: 'personal project'
    },

    {
        title: 'Dank Memer Hack',
        tech: ['python', 'pynput'],
        extraInfo: 'A hack wriiten in python that automatically farms coins and other valubles on the famous discord bot Dank Memer. It uses the pynput library to automate the key presses.',
        link: 'https://github.com/jeevesh2002/Dank',
        type: 'personal project'
    },

    {
        title: 'LAN-Messenger',
        tech: ['python','sockets', 'networking'],
        extraInfo: 'A command line clone of the famous OMEGLE website. It connects you to a random person the same network and you can chat with them. It uses sockets for creating a connection between the two users.',
        link: 'https://github.com/jeevesh2002/LAN-Messaging',
        type: 'personal project'
    },

    {
        title: 'Jigsaw-Web',
        tech: ['python', 'opencv', 'numpy', 'threading', 'flask', 'html', 'css', 'js', 'heroku'],
        extraInfo: 'A flask web app that allows you to create jigsaw puzzles from images. It uses the opencv library to create the jigsaw puzzle. It is hosted on Heroku.',
        link: 'https://github.com/jeevesh2002/Jigsaw-Web',
        type: 'personal project'
    },   

]

const MyProjects = () => {

    const [viewMore, setViewMore] = useState(false)

    return (
        <div className="container" id='myProjects'>
            <div className="container-fluid">
            <h2 className="containerHeader"><span className='red'>02.</span><span className="gradient-text">myProjects</span></h2>
            <div className="projectsContainer">
                {projects && projects.map((project) => (
                    <Project key={project.title} type={project.type} title={project.title} details={project.extraInfo} link={project.link} repo={project.repo} techs={project.tech} images={project.images} />
                ))}
            </div>

            <div className={`otherProjectsContainer ${ viewMore ? 'grow2' : '' }`}>
                <h2 className="containerSubHeader otherProjectsSubHeader"><span className='red'>//</span>otherProjects</h2>
                <div className='otherProjectGrid'>
                    {otherProjects && otherProjects.map((project) => (
                        <OtherProject title={project.title} tech={project.tech} extraInfo={project.extraInfo} repo={project.repo} link={project.link} type={project.type} key={project.title} />
                    ))}
                </div>
            </div>

            
            </div>
            <div className="btn btn-primary viewMoreProjects" onClick={() => setViewMore(!viewMore)}>
                {viewMore ? 'close' : 'otherProjects'}
                {viewMore ? <Icon icon="charm:chevron-up" className='chevronIcon' />
                    : <Icon icon="ci:chevron-big-down" className='chevronIcon' />}
            </div>
        </div>
    );
}

export default MyProjects;