
//icons
import { Icon } from '@iconify/react';
//hooks
import { useState, useEffect } from 'react';


const awardsData = [{"description":"7th person (globally) to completely finish all the labs successfully in IBM Quantum Spring Challenge 2023", "title":"IBM Quantum Spring Challenge 2023"},{ "description": "Awarded the National Top 1 % certificate in NSEJS by the Indian Association of Physics Teachers.", "title": "National Top 1%" }, { "description": "Secured 4th place in Microsoft's challenge at the iQuHack 2023, which was focused on optimizing quantum circuits and specifically quantum oracles. The challenge involved rewriting the code of quantum oracles to maintain their correctness while using minimal resources, such as logical qubits and cycles in the circuits.", "title": "MIT iQuHACK 2023" }, { "description": "Contributed to the IBM Qiskit Ecosystem by developing and implementing features, fixing bugs, and creating educational projects to assist beginners in understanding quantum computing using Qiskit.", "title": "IBM Qiskit Ecosystem" }, { "description": "Secured 2nd place in the Quantum Challange of the ICPC Algo Queens contest 2023.", "title": "ICPC Algo Queens 2023" }, { "description": "Participated in Hacktober 2022 and contributed to open-source projects. Developed and implemented new features, fixed bugs, refactored code, and contributed to documentation. Successfully completed and won the Hacktobefest 2022 challenge.", "title": "Hacktoberfest 2022" }, { "description": "Contributed to the Open Source USACO Guide for competitive programming (Bronze Section).", "title": "USACO" }];

const Awards = () => {
    const [awards, setAwards] = useState(awardsData);

    return (
        <div className="container" id='aboutMe'>
            <div className='container-fluid'>
                <h2 className="containerHeader "><span className='red'></span><span className='gradient-text'>-&gt; Achievements/Awards</span></h2>
                <div className="innerContainer">
                    <div className="aboutMe">
                        {awards.map((award, index) => (
                            <div key={index}>
                                <strong><p className='aqua'>{award.title}</p></strong>
                                <em>{award.description}</em>
                                <br />
                                <br />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Awards;