const Hero = ({ menuOpen, setMenuOpen }) => {
    return (
        <div className="heroContainer">
            {menuOpen && <div className="darkOverlay" onClick={() => setMenuOpen(false)}></div>}
            <div className="innerHero">
                <div className="leftInnerHero">
                    <div className="avatarContainer">
                        <img src='https://github.com/jeevesh2002/assets/raw/master/avatar.jpg' alt="avatar" />
                    </div>
                    <div className="avatarContainerShadow"></div>
                    <h1 className='heroIntroHeading gradient-text'>hello, i'm <span className='firstNameHero'>Jeevesh Krishna</span>,</h1>
                    <p className='heroIntroParagraph gradient-text'>A Full Stack Developer, ML Enthusiast and a Technophile.</p>
                </div>
                <div className="rightInnerHero">
                    <div className="gradient-text1">
                        <p>{'dev {'}</p>
                        <br />
                        <p className="code firstNameCode">{'firstName : "Jeevesh Krishna",'}</p>
                        <p className="code lastNameCode">{'lastName : "Arigala",'}</p>
                        <p className="code nationalityCode">{'nationality : "Indian",'}</p>
                        <p className="code infoCode">{'info : "passionate software developer"'}</p>
                        <br />
                        <p>{'};'}</p>
                    </div>
                    <div>
                        <a href='#myProjects' className='btn btn-primary heroButton'>viewProjects</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;