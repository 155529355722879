import Extra from "./Extra";

const extras = [
    {
        title: 'ACM Student Chapter SSN',
        position: 'Technical Member',
        date: '(Jun 2021 - Present)',
        details: "As a Core Committe Member of the Technical @ ACM Student Chapter SSN, I've organized events, workshops, and hackathons. I also participate in the development of the website and the social media pages of the chapter.",
        images: ['https://github.com/jeevesh2002/assets/blob/master/ssn-acm-home.png?raw=true','https://github.com/jeevesh2002/assets/blob/master/ssn-acm-tech-team.png?raw=true'],
        link: 'https://ssn.acm.org/',
    },
    {
        title: 'Google Developer Student Club SSN',
        position: 'Cybersecurity Team Member',
        date: '(Aug 2022 - Present)',
        details: "I am responsible for helping the Google Developer Student Club team in all domains related to cybersecurity.",
        images: ['https://github.com/jeevesh2002/assets/blob/master/gdsc-main.png?raw=true','https://github.com/jeevesh2002/assets/blob/master/gdsc-team.png?raw=true'],
        link: 'https://www.linkedin.com/company/gdsc-ssn-college-of-engineering',
    },
    {
        title: 'SSN CyberSecurity Club',
        position: 'Co-Founder, Vice Chairperson',
        date: '(Jan 2022 - Present)',
        details: 'Being a Co-Founder and Vice Chairperson of the SSN CyberSecurity Club,  I lead and manage the stakeholders ensuring smooth functioning of the club by co-ordinating with  other members of the club and the faculty advisors for planning and organizing events, workshops, and hackathons for the club.',
        images: ['https://github.com/jeevesh2002/assets/blob/master/ssn-cyber-home.png?raw=true','https://github.com/jeevesh2002/assets/blob/master/ssn-cyber-team.png?raw=true'],
        link: 'https://sites.google.com/ssn.edu.in/ssncybersecurityclub/home',
    },
    {
        title: 'National Service Scheme',
        position: 'Student Volunteer',
        date: '(Jan 2021 - Present)',
        details: 'Being a proud NSS volunteer I participate in various social service activities organized by the NSS unit of SSN. I also conduct events and workshops as a part of NSS.',
        images: ['https://github.com/jeevesh2002/assets/blob/master/nss1.jpg?raw=true','https://github.com/jeevesh2002/assets/blob/master/nss2.jpg?raw=true','https://github.com/jeevesh2002/assets/blob/master/nss3.jpg?raw=true','https://github.com/jeevesh2002/assets/blob/master/nss5.jpg?raw=true','https://github.com/jeevesh2002/assets/blob/master/nss6.jpg?raw=true','https://github.com/jeevesh2002/assets/blob/master/nss9.jpg?raw=true'],
        link: 'https://www.instagram.com/nssofssn/?hl=en',
    },

    
];

const MyExtra = () => {


    return (
        <div className="container">
                        <div className='container-fluid'>

            <h2 className="containerHeader"><span className='red'></span><span className="gradient-text"><span className="gradient-text">-> Experience</span></span></h2>
            <div className="extrasContainer">
                {extras && extras.map((extra) => (
                    <Extra key={extra.title} title={extra.title} position={extra.position} date={extra.date} details={extra.details} link={extra.link} images={extra.images} />
                ))}
            </div>
            </div>
        </div>
    );
}

export default MyExtra;