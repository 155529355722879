//icons
import { Icon } from '@iconify/react';


const ContactMe = () => {
    return (
        <div className="container" id="contactMe">
            <h2 className="containerHeader"><span className='red'>03.</span><span className='gradient-text'>contactMe</span><a href='https://github.com/jeevesh2002/assets/blob/master/pgp.key?raw=true' className='btn btn-primary btn-pgp'>My PGP Key</a></h2>
            <form action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfBMZBuocqlMf0h7lbHj07Ru5kjm9N0qPgk-tCTfRhFxQsOfQ/formResponse" method="POST">
                <small>from:</small>
                <input type="text" id='name' name="entry.1834405736" placeholder="enter your name..." required />
                <small></small>
                <input type="email" id='email' name="entry.675011971" placeholder="enter your email..." required />
                <small></small>
                <input type="tel" id='email' name="entry.790022626" placeholder="enter your mobile number..." />
                <small>to:</small>
                <p id="myEmail">jeeveshkrishna2010708@ssn.edu.in</p>
                <small>message:</small>
                <textarea name='entry.1709082491' id='textarea' rows='10' cols='20'
                    maxLength='5000'
                    placeholder="write your message.." required>
                </textarea>
                <button type="submit" className="btn btn-primary sendBtn">send <Icon icon="fluent:send-20-filled" className='sendIcon' />
                </button>
            </form>
            {/* <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1sSGvZlEMRym5-g5AvldatKfA-_FBT8ngnji92GwmHn1L35hIwBLLvWbXuuxFFE-qG9Tgd3VOQ?gv=true" style={{ border: 10, backgroundColor:'#ffffff', margin:'5%' }} width="100%" height="600" frameborder="0" title="My Calendar"></iframe> */}


        </div>

    );
}

export default ContactMe;